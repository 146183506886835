function setupFilters() {
  const archiveResources = document.getElementById('archive-resources');

  if (!archiveResources) {
    return;
  }

  let currentCategoryElement = archiveResources.querySelector('.archive-resources__filters-button.active');
  const archiveResourcesContainer = archiveResources.querySelector('.archive-resources__resources');
  const archiveResourcesButtons = archiveResources.querySelector('.archive-resources__filters');
  const searchInput = archiveResources.querySelector('.archive-resources__search-input');
  let isSearching = false;

  //SET EVENT HANDLERS
  //category handler
  document.addEventListener('click', function(e) {
    if(e.target.classList.contains('archive-resources__filters-button')) {

      currentCategoryElement.classList.remove('active');
      currentCategoryElement.removeAttribute('aria-current');

      currentCategoryElement = e.target;

      currentCategoryElement.classList.add('active');
      currentCategoryElement.setAttribute('aria-current', 'page');

      filterResources();
    }

    //page handler
    if (e.target.classList.contains('resources-pagination__link')) {
      filterResources(e.target.dataset.page);

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }

    //search button handler
    if (e.target.classList.contains('archive-resources__search-button')) {
      filterResources();
    }

    //reset button handler
    if (e.target.classList.contains('archive-resources__search-results-button')) {
      searchInput.value = '';
      filterResources();
    }
  })

  //search input handler
  if (searchInput) {
    searchInput.addEventListener('keydown', function(e) {
      if (e.key === "Enter") {
        filterResources();
      }
    })
  }

  //AJAX HANDLER
  function filterResources(page = 1) {
    if (!isSearching) {
			isSearching = true;
      archiveResourcesContainer.classList.add('loading');
      archiveResourcesButtons.classList.add('loading');
		}

    let url = new URL(window.location.href);
    let params = new URLSearchParams();

    const formData = new FormData();
		formData.append("action", "filter_resources");
    formData.append("page", page);

    if( currentCategoryElement && currentCategoryElement.dataset.category !== 'all') {
      formData.append("category", currentCategoryElement.dataset.category);
      params.set('category', currentCategoryElement.dataset.category);
    }


    if(searchInput && searchInput.value !== '') {
      formData.append("search_string", searchInput.value);
      params.set('search', searchInput.value);
    }

    if (page !== 1) {
      params.set('page', page);
    }

    url.search = params.toString();
    history.pushState({}, "", url);

    fetch(themeVars.ajax_url, {
			method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
		})
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error("Failed to fetch data");
      }
    })
    .then((response) => {
      isSearching = false;
      archiveResourcesContainer.classList.remove('loading');
      archiveResourcesButtons.classList.remove('loading');

      archiveResourcesContainer.innerHTML = ""; //fill posts container with results
      if (response && response.length) {
        archiveResourcesContainer.innerHTML = response;
      }
    })
    .catch((error) => {
      console.error("Error: ", error);
      archiveResourcesContainer.classList.remove('loading');
      archiveResourcesButtons.classList.remove('loading');
    });
  }

  //CHECK GET PARAMETERS, SET VALUES
  const categoryParam = getParameterByName("category");
  if (categoryParam) {
    currentCategoryElement.classList.remove('active');
    currentCategoryElement.removeAttribute('aria-current');

    currentCategoryElement = archiveResources.querySelector(`.archive-resources__filters-button[data-category=${categoryParam}]`);

    if (currentCategoryElement) {
      currentCategoryElement.classList.add('active');
      currentCategoryElement.setAttribute('aria-current', 'page');
    }
  }

  const searchParam = getParameterByName("search");
  if (searchParam) {
   searchInput.value = searchParam;
  }

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
}

export { setupFilters }
