export const addArrowsToBtns = ( arrowLeft, arrowTop, arrowBottom, arrowExternal, arrowSocial, link ) => {
  const arrowBtnsLeft = document.querySelectorAll(arrowLeft);
  const arrowBtnsTop = document.querySelectorAll(arrowTop);
  const arrowBtnsBottom = document.querySelectorAll(arrowBottom);
  const arrowBtnsExternal = document.querySelectorAll(arrowExternal);
  const arrowBtnsSocial = document.querySelectorAll(arrowSocial);
  const arrowBtns = [...arrowBtnsLeft, ...arrowBtnsTop, ...arrowBtnsBottom,  ...arrowBtnsExternal, ...arrowBtnsSocial];
  if (!arrowBtns) return;

  arrowBtns.forEach(btn => {
    const btnLink = btn.querySelector( link );
    if(btnLink) {
      btnLink.insertAdjacentHTML('beforeend', '<span class="wp-block-button__link--arrow"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 11.5C5.72386 11.5 5.5 11.7239 5.5 12C5.5 12.2761 5.72386 12.5 6 12.5V11.5ZM18.3536 12.3536C18.5488 12.1583 18.5488 11.8417 18.3536 11.6464L15.1716 8.46447C14.9763 8.2692 14.6597 8.2692 14.4645 8.46447C14.2692 8.65973 14.2692 8.97631 14.4645 9.17157L17.2929 12L14.4645 14.8284C14.2692 15.0237 14.2692 15.3403 14.4645 15.5355C14.6597 15.7308 14.9763 15.7308 15.1716 15.5355L18.3536 12.3536ZM6 12.5H18V11.5H6V12.5Z" fill="#131A3E"/><rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#131A3E"/></svg></span>');
    }
  });
}