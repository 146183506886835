export const playVideoOnce = () => {
  const parentElements = document.querySelectorAll('.play-once');

  if (parentElements.length === 0) {
    return;
  }

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5, // The video should be at least 50% visible in the viewport
  };

  const observerCallback = (entries, observer) => {
    entries.forEach(entry => {
      const video = entry.target;

      // If the video is in the viewport and hasn't been played before
      if (entry.isIntersecting && !video.dataset.hasPlayed) {
        video.play(); // Play the video
        video.dataset.hasPlayed = 'true'; // Mark it as played

        // Stop observing the video once it has been played
        observer.unobserve(video);
      }
    });
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  parentElements.forEach(parent => {
    const videos = parent.querySelectorAll('video');

    if (videos.length === 0) {
      console.warn('No video elements found in:', parent);
      return;
    }

    videos.forEach(video => {
      observer.observe(video);
    });
  });
};
