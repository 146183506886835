export const gridAnimation = ( block ) => {
  const children = block.querySelectorAll(':scope > div');
  let delay = 400;

  const duration = block.classList.contains('animate-items--faster') ? '480' : '600';

  children.forEach((child) => {
    child.setAttribute('data-aos', 'fade-up');
    child.setAttribute('data-aos-delay', delay);
    child.setAttribute('data-aos-duration', duration);
    delay += 200;
  });
}
