export const isInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export const initAnimation = ( array, func ) => {
    if(!array) return;

    array.forEach(item => {
        if (isInViewport(item) && !item.classList.contains('init')) {
            item.classList.add('init');

            if(func) func(item);
        }
    })
}

export const topSectionInViewport = ( array, offset, func ) => {
    if(!array) return;
    array.forEach(el => {

      const rect = el.getBoundingClientRect();
      if (!el.classList.contains('init') && (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))) {
        el.classList.add('init')
      }

      if(func) func(el);
    })
}
