import AOS from "aos";
import "./utils/font-awesome";
import "./modules/menu";
import "./modules/forms";
import "./modules/scrollToSection";
import { loopVideosTwice } from "./modules/loopTwice.js";
import { playVideoOnce } from "./modules/playVideoOnce.js";
import {addArrowsToBtns} from "./modules/buttons";
import {animationTitle} from "./modules/animationTitle";
import {separatorAnimation} from "./modules/separatorAnimation";
import {initAnimation, topSectionInViewport} from "./modules/isInViewport";
import {counterInit} from "./modules/counter";
import { firstLetterAnimation } from "./modules/firstLetterAnimation";
import { gridAnimation } from "./modules/gridAnimation";
import { updateAnimationSliderDuration } from "./modules/updateAnimationSliderDuration";
import { parallax } from "./modules/parallax";
import { setupFilters } from "./modules/resourcesFilters.js";

document.addEventListener('DOMContentLoaded', function(){
  setupFilters();
  const gridAnimationBlocks = document.querySelectorAll('.animate-items');
  if (gridAnimationBlocks.length > 0) {
    gridAnimationBlocks.forEach((block) => {
      gridAnimation(block);
    });
  }

  setTimeout(() => {
    const lottieBlocks = document.querySelectorAll('.wp-block-gb-lottiefiles');

    // Stop all animations initially
    lottieBlocks.forEach((block) => {
      const lottieInstance = block.querySelector('lottie-player');
      if (lottieInstance && typeof lottieInstance.stop === 'function') {
        lottieInstance.stop();
      }
    });

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const block = entry.target;
            const lottieInstance = block.querySelector('lottie-player');
            if (lottieInstance && typeof lottieInstance.play === 'function') {
              lottieInstance.play();
            }
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    // Observe each block
    lottieBlocks.forEach((block) => observer.observe(block));
  }, 1000);
})
const numberBox = document.querySelectorAll('.sk-counter');
const firstLetterAnimationBlocks = document.querySelectorAll('.first-letter-animation');
const bannerSections = document.querySelectorAll('.banner.gutenberg-block');
const titleAnimations = document.querySelectorAll('.wp-block-cgb-animation-title-xylyxbio-bloks');
document.addEventListener("touchstart", function() {}, {passive: true});

window.addEventListener('load', () => {
  addArrowsToBtns('.wp-block-button.is-style-arrow', '.wp-block-button.is-style-arrow-top', '.wp-block-button.is-style-arrow-bottom', '.wp-block-button.is-style-arrow-external', '.wp-block-button.is-style-arrow-social', '.wp-block-button__link');
  animationTitle('.animation-title__subtitle');
  animationTitle('.animation-title__title');
  separatorAnimation();
  parallax(".hero-inner.parallax .wp-block-cover__image-background", 25);
  parallax(".hero-inner.parallax .wp-block-cover__video-background", 25);
});

window.onload = () => {
  AOS.init({
    easing: "ease",
    duration: 500,
    offset: 0,
    delay: 50,
    once: true,
    anchorPlacement: 'top-bottom',
  });

  updateAnimationSliderDuration();
  topSectionInViewport(titleAnimations, 100);
  initAnimation(firstLetterAnimationBlocks, firstLetterAnimation);
  loopVideosTwice();
  playVideoOnce();

  setTimeout(() => {
    document.body.background = "#ffffff";
    document.querySelector('#loader').classList.add('loading');
  }, 100);

  setTimeout(() => {
    if(document.querySelector('.hero.gutenberg-block')) document.querySelector('.hero.gutenberg-block').classList.add('init');
  }, 600);
};

const isApple = /Mac|iPod|iPhone|iPad/.test(navigator.platform);

if (isApple) {
  window.addEventListener('unload', () => {
    document.querySelector('#loader').classList.remove('loading');
  });
} else {
  window.addEventListener('beforeunload', () => {
    document.querySelector('#loader').classList.remove('loading');
  });
}

window.addEventListener('resize', () => {
  updateAnimationSliderDuration();
});

document.addEventListener('scroll', function () {
  initAnimation(numberBox, counterInit);
  initAnimation(firstLetterAnimationBlocks, firstLetterAnimation);
  topSectionInViewport(bannerSections, 300);
  topSectionInViewport(titleAnimations, 100);
});
