const wrappWords = ( blockWords ) => {
  let content = '';

  blockWords.forEach( (word, index) => {
    content += `<span class="word"><span class="word__animation" style="transition-delay:${(index + 1) * 50}ms;">${word}</span></span> `;
  });

  return content;
}

export const animationTitle = (content) => {
  const animationTitleBlocks = document.querySelectorAll(content);
  if(!animationTitleBlocks) return;

  animationTitleBlocks.forEach(block => {
    const blockWords = block.textContent.split(" ");
    const blockTextContent = wrappWords(blockWords);
    block.textContent = '';
    block.insertAdjacentHTML('beforeend', blockTextContent);
  });
}
