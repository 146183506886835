export const loopVideosTwice = () => {
  const parentElements = document.querySelectorAll( '.loop-twice' );

  if ( parentElements.length === 0 ) {
    return;
  }

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  };

  const observerCallback = ( entries, observer ) => {
    entries.forEach( entry => {
      const video = entry.target;

      if ( !video.dataset.playCount ) {
        video.dataset.playCount = 0;
      }

      const playHandler = () => {
        video.dataset.playCount++;
        if ( video.dataset.playCount >= 2 ) {
          video.removeEventListener( 'ended', playHandler );
          observer.unobserve( video );
        } else {
          video.play();
        }
      };

      if ( entry.isIntersecting ) {
        if ( video.dataset.playCount < 2 ) {
          video.addEventListener( 'ended', playHandler );
          video.play();
        }
      } else {
        video.pause();
        video.removeEventListener( 'ended', playHandler );
      }
    } );
  };

  const observer = new IntersectionObserver( observerCallback, observerOptions );

  parentElements.forEach( parent => {
    const videos = parent.querySelectorAll( 'video' );

    if ( videos.length === 0 ) {
      console.warn( 'No video elements found in:', parent );
      return;
    }

    videos.forEach( video => {
      observer.observe( video );
    } );
  } );
};
